<template>
  <section class="container">
    <Header :active="getActiveBulkOrder" />
    <Details :active="getActiveBulkOrder" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header";
import Details from "./Details";
export default {
  components: {
    Header,
    Details
  },
  computed: {
    ...mapGetters(["getActiveBulkOrder"])
  },
  metaInfo: {
    title: "New Batch Order Item | WOW"
  }
};
</script>

<style scoped lang="scss">
</style>