<template>
  <div class="container">
    <div class="flex__left">
      <router-link :to="`/batch-order/${active._id}/batch-items`" class="breadcrumb">
        <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Batch order
      </router-link>
    </div>
    <h1 class="text__center padding__md">Batch Item Details</h1>
  </div>
</template>

<script>
export default {
  props: ["active"]
};
</script>